/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import DeleteModal from "modal/deleteModal";
import VoiceModal from "modal/voiceModal";
import UpdateVoiceModal from "modal/updateVaoiceModal";
import Swal from "sweetalert2";
import { deleteCallVoice, getVoiceList } from "services/voices";
import Loader from "components/Loader/Loader";
import { useToast } from "@chakra-ui/react";
import Pagination from "components/pagenation";

const Voices = () => {
  const toast = useToast();
  const [isAddVoices, setIsVoices] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModal, setIsEditModalOpen] = useState(false);
  const [selectedItemId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [voiceList, setVoiceList] = useState([]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [rowId, setRowId] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const indexOfLastUser = currentPage * perPage;
  const indexOfFirstUser = indexOfLastUser - perPage;
  const currentVoice = voiceList?.slice(indexOfFirstUser, indexOfLastUser);

  const handleAddVoicesClose = () => {
    setIsVoices(false);
  };

  const handleAddVoicesModal = () => {
    setIsVoices(!isAddVoices);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleModal = (item: any) => {
    setIsModalOpen(!isModalOpen);
    setRowId(item?.id);
  };

  const handleEditClose = () => {
    setIsEditModalOpen(false);
  };

  // const handleEditModal = (item: any) => {
  //   setIsEditModalOpen(!isEditModal);
  //   setSelectedItemId(item);
  // };

  useEffect(() => {
    const fetchVoiceList = async () => {
      setLoading(true);
      try {
        const data = await getVoiceList();
        setVoiceList(data?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Failed to fetch voice list. Please try again.",
        });
      }
    };
    fetchVoiceList();
  }, [refresh]);

  useEffect(() => {
    if (isConfirm === true) {
      deleteCallVoice(rowId)
        .then((response) => {
          if (response.success) {
            handleClose();
            setRefresh(!refresh);
            toast({
              description: response?.message,
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          } else {
            toast({
              description: response?.message,
              status: "info",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          console.error("Error Deleting Assistant:", error);
        });
    }
  }, [isConfirm]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mt-4">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="mb-4 flex justify-end">
            <button
              onClick={() => handleAddVoicesModal()}
              className="focus:shadow-outline rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none"
            >
              Add Voice Assistant
            </button>
          </div>
          <div className="relative overflow-x-auto rounded border-[1px] border-gray-200 p-3 shadow-md dark:border-[#ffffff33] dark:bg-navy-900 sm:rounded-lg ">
            <table className="responsive-table text-black w-full text-left text-sm dark:text-gray-400 rtl:text-right">
              <thead className="text-black-700 border-b-2 text-xs uppercase  dark:bg-navy-900 dark:text-white">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Display Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Accent
                  </th>
                  <th scope="col" className="px-6 py-3">
                    AI Modal
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentVoice &&
                  currentVoice?.length > 0 &&
                  currentVoice?.map((item: any, index: number) => (
                    <tr
                      key={index}
                      className={`border-b ${
                        index % 2 === 0
                          ? "dark:bg-navy-900 dark:text-white"
                          : "dark:bg-navy-900 dark:text-white"
                      }`}
                    >
                      <th
                        scope="row"
                        className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                      >
                        {item?.name}
                      </th>
                      <td className="px-6 py-4">{item?.displayname}</td>
                      <td className="px-6 py-4">{item?.accent}</td>
                      <td className="px-6 py-4">{item?.ai_modal}</td>
                      <td className="flex gap-2 px-6 py-4">
                        <div className="flex gap-4">
                          {/* <MdEdit
                            onClick={() => handleEditModal(item)}
                            className="h-6 w-6 cursor-pointer font-medium text-blue-600 hover:underline dark:text-blue-500"
                          /> */}
                          <MdDelete
                            onClick={() => handleModal(item)}
                            className="h-6 w-6 cursor-pointer font-medium text-red-600 hover:underline dark:text-red-500"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      <DeleteModal
        isOpen={isModalOpen}
        handleClose={handleClose}
        setIsConfirm={setIsConfirm}
      />
      <VoiceModal
        isOpen={isAddVoices}
        handleClose={handleAddVoicesClose}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      <UpdateVoiceModal
        isOpen={isEditModal}
        handleClose={handleEditClose}
        rowData={selectedItemId}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      <Pagination
        perPage={perPage}
        allTotal={voiceList?.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );
};

export default Voices;
