import { Tag, useToast } from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import Pagination from "components/pagenation";
import React, { useEffect, useState } from "react";
import { getUserList, updateUserAccountStatus } from "services/user";
import Swal from "sweetalert2";
import { formatDateAmPm } from "utils/utils";

const UserList = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);

  const toggle = (index: number, id: string) => {
    const newData = [...userList];
    newData[index].action =
      newData[index].action === "enable" ? "disable" : "enable";
    setUserList(newData);

    if (newData[index].action === "enable") {
      updateUserAccountStatus(id).then((response: any) => {
        if (response.status === 200) {
          setRefresh(!refresh);
          toast({
            description: response.message,
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          getUserList();
        } else {
          toast({
            description: response.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          throw new Error(" User Account Disable failed");
        }
      });
    } else {
      updateUserAccountStatus(id).then((response: any) => {
        if (response.status === 200) {
          setRefresh(!refresh);
          toast({
            description: response.message,
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          getUserList();
        } else {
          toast({
            description: response.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          throw new Error(" User Account Disable failed");
        }
      });
    }
  };

  useEffect(() => {
    const fetchVoiceList = async () => {
      setLoading(true);
      try {
        const data = await getUserList();
        if (data?.data) {
          setUserList(data?.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Failed to fetch voice list. Please try again.",
        });
      }
    };
    fetchVoiceList();
  }, [refresh]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const indexOfLastUser = currentPage * perPage;
  const indexOfFirstUser = indexOfLastUser - perPage;
  const currentUsers = userList?.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <div className="mt-8">
      {loading ? (
        <Loader />
      ) : (
        <div className="relative overflow-x-auto rounded border-[1px] border-gray-200 p-3 shadow-md dark:border-[#ffffff33] dark:bg-navy-900 sm:rounded-lg ">
          <table className="responsive-table text-black w-full text-left text-sm dark:text-gray-400 rtl:text-right">
            <thead className="text-black-700 border-b-2 text-xs uppercase  dark:bg-navy-900 dark:text-white">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Full Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Phone Number
                </th>
                <th scope="col" className="px-6 py-3">
                  Amount
                </th>
                <th scope="col" className="px-6 py-3">
                  Created At
                </th>
                <th scope="col" className="px-6 py-3">
                  Email Verify
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentUsers?.map((item, index) => (
                <tr
                  key={index}
                  className={`border-b ${
                    index % 2 === 0 ? "even:bg-gray-50" : "odd:bg-white"
                  } ${
                    index % 2 === 0
                      ? "dark:bg-navy-900 dark:text-white"
                      : "dark:bg-navy-900 dark:text-white"
                  }`}
                >
                  <th
                    data-label="Full Name"
                    className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                  >
                    {item?.first_name} {item?.last_name}
                  </th>
                  <td className="px-6 py-4" data-label="Email">
                    {item?.email}
                  </td>
                  <td className="px-6 py-4" data-label="Phone">
                    {item?.phone}
                  </td>
                  <td className="px-6 py-4" data-label="Phone">
                    ${item?.amount}
                  </td>
                  <td className="px-6 py-4" data-label="Created At">
                    {formatDateAmPm(item?.created_at)}
                  </td>
                  <td className="px-6 py-4" data-label="Phone">
                    {item?.is_verify_email ? (
                      <Tag variant="solid" colorScheme="green">
                        Verified
                      </Tag>
                    ) : (
                      <Tag variant="solid" colorScheme="yellow">
                        Pending
                      </Tag>
                    )}
                  </td>
                  <td className="px-6 py-4" data-label="Action">
                    <label className="flex cursor-pointer items-center justify-center sm:justify-end">
                      <div className="relative">
                        <input
                          type="checkbox"
                          className="sr-only"
                          checked={item.deleted_at === null}
                          onChange={() => toggle(index, item.id)} // Pass the item ID to the toggle function
                        />
                        <div className="block h-6 w-10 rounded-full bg-gray-400"></div>
                        <div
                          className={`absolute left-0 top-0 h-6 w-6 rounded-full transition-transform ${
                            item.deleted_at === null
                              ? "translate-x-full bg-green-400"
                              : "translate-x-0 bg-red-400"
                          }`}
                        ></div>
                      </div>
                      <div className="ml-3 font-medium text-gray-700 dark:text-gray-300">
                        {item.deleted_at === null ? "Enabled" : "Disabled"}
                      </div>
                    </label>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Pagination
        perPage={perPage}
        allTotal={userList?.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );
};

export default UserList;
