import { PiCurrencyCircleDollarBold, PiUserList } from "react-icons/pi";
import UserList from "views/admin/userList";
import Voices from "views/admin/voices";
import {
  MdOutlineSupportAgent,
  MdProductionQuantityLimits,
  MdRequestQuote,
  MdSettingsVoice,
} from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { GiFlagObjective } from "react-icons/gi";
import { FaIndustry } from "react-icons/fa6";
import Objective from "views/admin/objective";
import Roles from "views/admin/roles";
import Industries from "views/admin/industries";
import Login from "views/auth/logIn";
import { IoIosContacts } from "react-icons/io";
import { HiTemplate } from "react-icons/hi";
import TemplateList from "views/admin/TemplateList";
import WebsiteEnquiry from "views/admin/webEnquiry";
import TicketList from "views/admin/support";
import { RiCoupon3Fill } from "react-icons/ri";
import Coupon from "views/admin/coupan";
import { PiHandTapFill } from "react-icons/pi";
import WithdrawalRequest from "views/admin/withdrawalRequest";
import EnterpriseInquiry from "views/admin/enterpriseInquiry";
import Plans from "views/admin/plans";
import Products from "views/admin/products";
// import Setting from "components/setting";
// import Price from "components/price";
// import Editform from "components/form";
// import Profile from "views/admin/profile";
// import { BiDollar } from "react-icons/bi";

const routes = [
  {
    name: "UserList",
    layout: "/admin",
    path: "user-list",
    icon: <PiUserList className="h-6 w-6" />,
    component: <UserList />,
    sidebar: true,
  },
  {
    name: "Voices",
    layout: "/admin",
    path: "voices",
    icon: <MdSettingsVoice className="h-6 w-6" />,
    component: <Voices />,
    sidebar: true,
  },
  {
    name: "Products",
    layout: "/admin",
    path: "products",
    icon: <MdProductionQuantityLimits className="h-6 w-6" />,
    component: <Products />,
    sidebar: true,
  },
  {
    name: "Plans",
    layout: "/admin",
    path: "plans",
    icon: <PiCurrencyCircleDollarBold className="h-6 w-6" />,
    component: <Plans />,
    sidebar: true,
  },
  {
    name: "Industries",
    layout: "/admin",
    path: "industries",
    icon: <FaIndustry className="h-6 w-6" />,
    component: <Industries />,
    sidebar: true,
  },
  {
    name: "Roles",
    layout: "/admin",
    path: "roles",
    icon: <PiUserList className="h-6 w-6" />,
    component: <Roles />,
    sidebar: true,
  },
  {
    name: "Objective",
    layout: "/admin",
    path: "objective",
    icon: <GiFlagObjective className="h-6 w-6" />,
    component: <Objective />,
    sidebar: true,
  },
  {
    name: "Website-enquiry",
    layout: "/admin",
    path: "website-enquiry",
    icon: <IoIosContacts className="h-6 w-6" />,
    component: <WebsiteEnquiry />,
    sidebar: true,
  },
  {
    name: "Template",
    layout: "/admin",
    path: "template",
    icon: <HiTemplate className="h-6 w-6" />,
    component: <TemplateList />,
    sidebar: true,
  },
  // {
  //   name: "Setting",
  //   layout: "/admin",
  //   path: "setting",
  //   icon: <IoMdSettings className="h-6 w-6" />,
  //   component: <Setting />,
  // },
  {
    name: "Login",
    layout: "/auth",
    path: "login",
    icon: <IoMdSettings className="h-6 w-6" />,
    sidebar: false,
    component: <Login />,
  },
  {
    name: "Coupon",
    layout: "/admin",
    path: "Coupon",
    icon: <RiCoupon3Fill className="h-6 w-6" />,
    component: <Coupon />,
    sidebar: true,
  },
  {
    name: "Withdrawal Request",
    layout: "/admin",
    path: "withdrawal-request",
    icon: <MdRequestQuote className="h-6 w-6" />,
    component: <WithdrawalRequest />,
    sidebar: true,
  },
  {
    name: "Enterprise Inquiry",
    layout: "/admin",
    path: "enterprise-inquiry",
    icon: <PiHandTapFill className="h-6 w-6" />,
    component: <EnterpriseInquiry />,
    sidebar: true,
  },
  {
    name: "Support",
    layout: "/admin",
    path: "support",
    icon: <MdOutlineSupportAgent className="h-6 w-6" />,
    component: <TicketList />,
    sidebar: true,
  },
];
export default routes;
